export default {
    GET_SELECT_AUTH_REPRESENTATIVE:(state)=>{
        return state.selectAuthRepresentative
    },
    GET_RESULT:(state)=>{
        return state.Result
    },
    GET_EDIT_AUTH_REPRESENTATIVE:(state)=>{
        return state.editAuthRepresentative
    },
    GET_BIRTH_COUNTRY_AUTH_REPRESENTATIVE:(state)=> {
        return state.birthCountryAuthRepresentative
    }
   
}