export default {
    SET_SELECT_AUTH_REPRESENTATIVE(state,AuthRepresentative){
        state.selectAuthRepresentative = AuthRepresentative
    },
    SET_RESULT(state,result){
        state.Result = result
    },
    SET_EDIT_AUTH_REPRESENTATIVE(state,editAuthRepresentative){
        state.editRepresentative = editAuthRepresentative
    },
    SET_BIRTH_COUNTRY_AUTH_REPRESENTATIVE(state,birthCountryAuthRepresentative) {
        state.birthCountryAuthRepresentative = birthCountryAuthRepresentative
    }
  
    
}