export default {
    SET_SELECT_AUTH_SIGNATORY(state,AuthSignatory){
        state.selectAuthSignatory = AuthSignatory
    },
    SET_RESULT(state,result){
        state.Result = result
    },
    SET_EDIT_AUTH_SIGNATORY(state,editAuthSignatory){
        state.editAuthSignatory = editAuthSignatory
    },
    SET_BIRTH_COUNTRY_AUTH_SIGNATORY(state,birthCountryAuthSignatory) {
        state.birthCountryAuthSignatory = birthCountryAuthSignatory
    }
  
    
}