export default {
    GET_SELECT_PRINCIPAL:(state)=>{
        return state.selectPrincipal
    },
    GET_RESULT:(state)=>{
        return state.Result
    },
    GET_EDIT_PRINCIPAL:(state)=>{
        return state.editPrincipal
    },

    GET_COMPANY_PRINCIPAL: (state)=> {
        return state.companyPrincipals
    },
    GET_COUNT_PRINCIPAL: (state)=> {
        return state.countPrincipals
    }
}