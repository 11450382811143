export default {

    SET_SELECT_PRINCIPAL({commit},selectPrincipal){
        commit('SET_SELECT_PRINCIPAL',selectPrincipal)
    },
    SET_RESULT({commit},result){
        commit('SET_RESULT',result)
    },
    SET_EDIT_PRINCIPAL({commit},editPrincipal){
        commit('SET_EDIT_PRINCIPAL',editPrincipal)
    },

    SET_COMPANY_PRINCIPAL({commit}, companyPrincipals) {
        commit('SET_COMPANY_PRINCIPAL', companyPrincipals)
    },
    SET_COUNT_PRINCIPAL({commit}, countPrincipals) {
        commit('SET_COUNT_PRINCIPAL', countPrincipals)
    }
}