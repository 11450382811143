export default {
   
    SET_SELECT_AUTH_SIGNATORY({commit},authSignatory){
        commit('SET_SELECT_AUTH_SIGNATORY',authSignatory)
    },
    SET_RESULT({commit},result){
        commit('SET_RESULT',result)
    },
    SET_EDIT_AUTH_SIGNATORY({commit},editAuthSignatory){
        commit('SET_EDIT_AUTH_SIGNATORY',editAuthSignatory)
    },
    SET_BIRTH_COUNTRY_AUTH_SIGNATORY({commit}, birthCountryAuthSignatory){
        commit('SET_BIRTH_COUNTRY_AUTH_SIGNATORY', birthCountryAuthSignatory)
    }
   
}