export default {
    SET_SELECT_PRINCIPAL(state,principal){
        state.selectPrincipal = principal
    },
    SET_RESULT(state,result){
        state.Result = result
    },

    SET_COMPANY_PRINCIPAL(state, companyPrincipals) {
        state.companyPrincipals = companyPrincipals
    },
    SET_COUNT_PRINCIPAL(state, countPrincipals) {
        state.countPrincipals = countPrincipals
    }
}