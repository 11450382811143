export default {
   
    SET_SELECT_AUTH_REPRESENTATIVE({commit},AuthRepresentative){
        commit('SET_SELECT_AUTH_REPRESENTATIVE',AuthRepresentative)
    },
    SET_RESULT({commit},result){
        commit('SET_RESULT',result)
    },
    SET_EDIT_AUTH_REPRESENTATIVE({commit},editAuthRepresentative){
        commit('SET_EDIT_AUTH_REPRESENTATIVE',editAuthRepresentative)
    },
    SET_BIRTH_COUNTRY_AUTH_REPRESENTATIVE({commit}, birthCountryAuthRepresentative){
        commit('SET_BIRTH_COUNTRY_AUTH_REPRESENTATIVE', birthCountryAuthRepresentative)
    }
   
}