export default {
    GET_SELECT_AUTH_SIGNATORY:(state)=>{
        return state.selectAuthSignatory
    },
    GET_RESULT:(state)=>{
        return state.Result
    },
    GET_EDIT_AUTH_SIGNATORY:(state)=>{
        return state.editAuthSignatory
    },
    GET_BIRTH_COUNTRY_AUTH_SIGNATORY:(state)=> {
        return state.birthCountryAuthSignatory
    }
   
}