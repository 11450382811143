export default ({ app }, inject) => {
    const filterPayload = (payload) => {
      const filterObject = (inputObject) => {
        return Object.entries(inputObject).reduce((filteredObject, [key, value]) => {
          if (value && typeof value === 'object' && !Array.isArray(value)) {
            const nestedFilteredObject = filterObject(value)
            if (Object.keys(nestedFilteredObject).length > 0) {
              filteredObject[key] = nestedFilteredObject
            }
          } else if (value !== '' && value !== null && value !== undefined) {
            filteredObject[key] = value
          }
          return filteredObject
        }, {})
      }
      return filterObject(payload)
    }

    inject('filterPayload', filterPayload)
  }
